import React from 'react';
import { useSelector } from 'react-redux';

export const ItemDetails = React.forwardRef((props, ref) => {
  const storageBaseUrl = useSelector(
    (state) => state.dashboardReducer?.storeConfig?.storageBaseUrl
  );
  const currentItem = props.currentItem;

  let imagePath = '';

  if (storageBaseUrl && currentItem?.mediaPath) {
    imagePath = `${storageBaseUrl}${currentItem?.mediaPath}`;
  }
  return (
    <div ref={ref} className="main-area-header">
      <div className="current-food-box">
        <div className="current-img-box">
          <img
            src={
              imagePath ? imagePath : currentItem.urlS3 ? currentItem.urlS3 : ''
            }
            alt={currentItem.name ? currentItem.name : ''}
          />
        </div>
        <div className="current-food-body">
          <h1 className="food-title">
            {currentItem.name ? currentItem.name : ''}
          </h1>
          <p className="food-ingredients">
            {currentItem.description ? currentItem.description : ''}
          </p>
        </div>
      </div>
    </div>
  );
});
