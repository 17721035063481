import clsx from 'clsx';
import { PosIconPlus } from '../../assets/icons/PosIconPlus';
//import useRouteByMenuItemType from '../../customHooks/useRouteByMenuItemType';
import { useHistory } from 'react-router-dom';
import { openMenuItem } from '../../pages/Dashboard/action';
import { useDispatch, useSelector } from 'react-redux';
import { PosIconSilverware } from '../../assets/icons/PosIconSilverware';

const FourQuarterSection = ({
  item = {},
  onClick,
  parentPage,
  toggleItemEditMode,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cardCls = clsx({
    'half-single-card': true,
    selected:
      item.currentMenuItem?.mediaPath ||
      item.currentMenuItem?.urlS3 ||
      item.currentMenuItem?.name,
  });

  const titleCls = clsx({
    'half-item-title': true,
    'with-icon':
      (!item.currentMenuItem?.mediaPath || !item.currentMenuItem?.urlS3) &&
      !item.currentMenuItem?.name,
  });

  const storageBaseUrl = useSelector(
    (state) => state.dashboardReducer?.storeConfig?.storageBaseUrl
  );

  let imagePath = '';

  if (storageBaseUrl && item.currentMenuItem?.mediaPath) {
    imagePath = `${storageBaseUrl}${item.currentMenuItem?.mediaPath}`;
  }

  const linkToCustomise = `${parentPage}/menuitem/${item.currentMenuItem?._id}/customization`;

  const handleCustomiseItemClick = (event) => {
    event.stopPropagation();
    toggleItemEditMode(item.fourQuarterIndex);
    history.push(linkToCustomise);
    dispatch(openMenuItem({ ...item.currentMenuItem, isQuarter: true }, false));
  };

  return (
    <div className="half-card-wrap" key={item.fourQuarterIndex}>
      <button
        title={item.currentMenuItem?.name}
        className={cardCls}
        onClick={onClick}
      >
        <span className="half-item-img-wrap">
          {imagePath || item.currentMenuItem?.urlS3 ? (
            <img
              src={imagePath ? imagePath : item.currentMenuItem?.urlS3}
              alt=""
            />
          ) : (
            ''
          )}
        </span>
        <span className={titleCls}>
          {item.currentMenuItem?.name ? (
            <span className="inner-box">
              <span className="customize-half-title">
                {item.currentMenuItem?.name}
              </span>
              <span
                className="customize-half-btn"
                onClick={handleCustomiseItemClick}
              >
                <span className="customize-half-btn-title">Customize</span>
                <PosIconSilverware mainColor="#000000" title="Customise" />
              </span>
            </span>
          ) : (
            <span className="inner-box">
              <PosIconPlus mainColor="#ffffff" lineThickness={1.25} /> Add
            </span>
          )}
        </span>
      </button>
    </div>
  );
};

export default FourQuarterSection;
