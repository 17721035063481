import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import '../leaflet.css';
import '../leafletMarkerSlideTo';
import 'leaflet-rotatedmarker';

const L = window.L;
let marker = L.icon({
  iconUrl: `https://storage.googleapis.com/next-order-media/assets/marker.svg`,
  iconSize: [40, 50], // size of the icon
  iconAnchor: [0, 50],
});

class LeafletDriverOnTheWay extends PureComponent {
  constructor(props) {
    super(props);
    this.map = {};
    this.marker = {};
    this.route = {};
    this.svg = ``;
  }

  componentDidMount() {
    var container = L.DomUtil.get(this.props.id);
    if (container != null) {
      container._leaflet_id = null;
    }
    let icon = L.icon({
      iconUrl:
        'https://storage.googleapis.com/next-order-media/assets/Red-Cooper.svg',
      iconSize: [30, 40], // size of the icon
      iconAnchor: [15, 20],
    });
    L.mapbox.accessToken =
      'pk.eyJ1Ijoid2lsbHJpY2hhcmRzLTkwOCIsImEiOiJjbGJxN3VieXEwa2ZlM3FvOXptaGRzbzYzIn0.mVrLLr7zzbKbidhMPvL7pA';
    this.map = L.mapbox
      .map(this.props.id, null, {
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        maxZoom: 16,
        scrollWheelZoom: false,
      })
      .setView(
        [
          Number(this.props.driver.currentLocation.lat),
          Number(this.props.driver.currentLocation.long),
        ],
        16
      )
      .addLayer(
        L.mapbox.styleLayer(
          'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy',
          {
            tileSize: 256,
          }
        )
      );

    this.map.on('zoom', () => {
      this.updateRoute(this.props.driver);
    });

    //Current polyline for driver to delivery address
    this.route = L.Routing.control({
      router: L.Routing.osrmv1({
        serviceUrl: 'https://osrm-api.nextorder.co/route/v1',
      }),
      waypoints: [
        L.latLng(
          Number(this.props.driver.currentLocation.lat),
          Number(this.props.driver.currentLocation.long)
        ),
        L.latLng(this.props.delivery.lat, this.props.delivery.lng),
      ],
      show: true,
      waypointMode: 'snap',
      lineOptions: {
        styles: [{ color: 'rgb(226, 90, 40)', opacity: 1, weight: 5 }],
      },
      createMarker: () => {
        return false;
      },
      routeWhileDragging: true,
      reverseWaypoints: true,
      showAlternatives: false,
      useZoomParameter: true,
      autoRoute: true,
      altLineOptions: {
        styles: [
          { color: '#f47f02', opacity: 0.15, weight: 9 },
          { color: '#f4bb02', opacity: 0.8, weight: 6 },
          { color: '#f7de00', opacity: 0.5, weight: 2 },
        ],
      },
    }).addTo(this.map);

    var l1 = new window.google.maps.LatLng(
      this.props.driver.previousLocation.lat,
      this.props.driver.previousLocation.long
    );
    var l2 = new window.google.maps.LatLng(
      this.props.driver.currentLocation.lat,
      this.props.driver.currentLocation.long
    );
    var heading = window.google.maps.geometry.spherical.computeHeading(l1, l2);
    this.marker = L.marker(
      [
        this.props.driver.currentLocation.lat,
        this.props.driver.currentLocation.long,
      ],
      { rotationOrigin: 'center', rotationAngle: heading, icon }
    ).addTo(this.map);

    //Showing desination for delivery
    this.delivery = L.marker(
      [this.props.delivery.lat, this.props.delivery.lng],
      { rotationOrigin: 'center', icon: marker }
    ).addTo(this.map);

    setTimeout(() => {
      this.map.invalidateSize();
    }, 500);
  }
  componentWillReceiveProps(newProps) {
    var newLatLng = new L.LatLng(
      newProps.driver.currentLocation.lat,
      newProps.driver.currentLocation.long
    );
    var l1 = new window.google.maps.LatLng(
      newProps.driver.previousLocation.lat,
      newProps.driver.previousLocation.long
    );
    var l2 = new window.google.maps.LatLng(
      newProps.driver.currentLocation.lat,
      newProps.driver.currentLocation.long
    );
    let distance = window.google.maps.geometry.spherical.computeDistanceBetween(
      l1,
      l2
    );
    var heading = window.google.maps.geometry.spherical.computeHeading(l1, l2);
    if (heading < 0) heading = heading + 360;
    if (distance > 10) {
      this.marker.setRotationAngle(heading);
    }

    this.marker.slideTo(newLatLng, {
      duration: 2000,
      keepAtCenter: false,
    });
    setTimeout(() => this.updateRoute(newProps.driver), 1000);
    this.recenterMap(newLatLng);
  }

  updateRoute(driver) {
    this.route
      .getPlan()
      .setWaypoints([
        L.latLng(
          Number(driver.currentLocation.lat),
          Number(driver.currentLocation.long)
        ),
        L.latLng(this.props.delivery.lat, this.props.delivery.lng),
      ]);
  }
  recenterMap = (tt) => {
    this.map.setView(tt, this.map.getZoom(), {
      padding: [50, 50],
      animate: true,
      duration: 25,
    });
  };
  render() {
    return <div ref={this.props.id} id={this.props.id} />;
  }
}

LeafletDriverOnTheWay.propTypes = {
  delivery: PropTypes.any,
  driver: PropTypes.any,
  setTime: PropTypes.any,
};

export default LeafletDriverOnTheWay;
