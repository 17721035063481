import Button from 'components/Button';
import { kiosk_order_route, kiosk_route } from 'constants/routesConsts';
import { InfoIcon } from 'nexticons/solid';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './kiosk-splash.scss';
import KioskFooter from './KioskFooter';

export default function KioskStartScreen({ kioskFlowType }) {
  const { logo } = useSelector((state) => state.dashboardReducer.storeConfig);
  const logoPath = useSelector(
    (state) => state.dashboardReducer.storeConfig?.logoPath
  );
  const storageBaseUrl = useSelector(
    (state) => state.dashboardReducer?.storeConfig?.storageBaseUrl
  );

  const { primaryThemeColour, secondaryThemeColour } = useSelector(
    (state) => state.storeWebsiteLoaderDataReducer
  );
  const { enableAllOrderTypeKiosk } = useSelector(
    (state) => state.dashboardReducer?.orderSetup
  );

  const kioskRouteMap = {
    kioskWalkIn: kiosk_order_route,
    kioskPickup: '/kiosk/pickup/order',
    kioskDelivery: '/kiosk/delivery/order',
  };
  const history = useHistory();

  const timer = useRef(null);

  let logoPathUrl = '';
  if (storageBaseUrl && logoPath) {
    logoPathUrl = `${storageBaseUrl}${logoPath}`;
  }

  useEffect(() => {
    let timeOut = timer.current;

    timeOut = setTimeout(() => {
      history.push(kiosk_route);
    }, 15000);

    return () => {
      clearTimeout(timeOut);
    };
    // eslint-disable-next-line
  }, []);

  const onCancelOrder = (e) => {
    e?.stopPropagation();
    history.push(kiosk_route);
  };

  return (
    <div
      onClick={() =>
        enableAllOrderTypeKiosk
          ? history.push(kioskRouteMap[kioskFlowType])
          : history.push(kiosk_order_route)
      }
      className="splash-container"
      style={{
        cursor: 'pointer',
        backgroundColor: primaryThemeColour,
      }}
    >
      <div
        className="splash-hub"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          color: '#fff',
        }}
      >
        <InfoIcon color={'#fff'} style={{ width: '12vmin' }} />
        <h1
          style={{
            fontSize: '4vmin',
            textAlign: 'center',
          }}
        >
          Still thinking?
        </h1>
        <p
          style={{
            fontSize: '2vmin',
            textAlign: 'center',
          }}
        >
          Tap Below To Continue.
          <br />
          Otherwise, we'll start over again.
        </p>

        <br />
        <Button
          style={{
            maxWidth: 400,
            minWidth: 100,
            width: '100%',
            padding: 16,
            borderRadius: 100,
            color: '#fff',
            backgroundColor: secondaryThemeColour,
            fontSize: '2.7vmin',
            fontWeight: '500',
          }}
        >
          Continue
        </Button>
      </div>
      <KioskFooter
        logo={logoPathUrl ? logoPathUrl : logo}
        secondaryColor={secondaryThemeColour}
        buttonText={'Cancel Order'}
        onButtonClick={onCancelOrder}
      />
    </div>
  );
}
