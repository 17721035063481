import { useLayoutEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { PosIconSilverware } from '../../assets/icons/PosIconSilverware';
import { PosIconPlus } from '../../assets/icons/PosIconPlus';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  openMenuItem,
  toggleSpecialsCategoryEditMode,
  updateCurrentSpecialsCategoryItem,
} from '../../pages/Dashboard/action';
import useRouteByMenuItemType from '../../customHooks/useRouteByMenuItemType';
import {
  incrementItemQuantitySingleSize,
  isItemWithSingleSize,
} from '../../itemProcessor';
import useCategories from 'hooks/useCategories';

const SpecialsCategoryItem = ({ item, parentPage }) => {
  const categories = useCategories();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const activeSeatId = useSelector(
    (state) => state.dashboardReducer?.activeSeatId || ''
  );
  const currentOrder = useSelector(
    (state) => state.dashboardReducer?.currentOrder || null
  );
  const courseAutoAssignmentEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.courseAutoAssignmentEnabled
  );
  const storageBaseUrl = useSelector(
    (state) => state.dashboardReducer?.storeConfig?.storageBaseUrl
  );

  const currentItems = useMemo(
    () => item.currentMenuItems || [],
    [item.currentMenuItems]
  );
  const isHalfHalf = currentItems.length === 2;
  const isFourQuarter = currentItems.length === 4;
  const [hasSingleSizedItem, setHasSingleSizedItem] = useState(false);
  const { specialsId } = useParams();
  const cardCls = clsx({
    'specials-single-card': true,
    selected:
      currentItems[0]?.mediaPath || currentItems[0]?.urlS3 || item.displayName,
  });

  const titleCls = clsx({
    'specials-item-title': true,
    big:
      (currentItems[0]?.mediaPath || currentItems[0]?.urlS3) &&
      !item.displayName,
  });

  let imagePath = '';

  if (storageBaseUrl && currentItems[0]?.mediaPath) {
    imagePath = `${storageBaseUrl}${currentItems[0]?.mediaPath}`;
  }

  const handleClick = () => {
    history.push(
      `${parentPage}/specials/${specialsId}/${item.index}`,
      location.state
    );
    dispatch(toggleSpecialsCategoryEditMode(item.index));
  };

  useLayoutEffect(() => {
    if (item.menuItems?.length === 1) {
      // Add activeSeatId to the item if it is a dine in order
      const selectedItem =
        currentOrder?.orderType === '3'
          ? { ...item.menuItems[0], activeSeatId: activeSeatId }
          : item.menuItems[0];

      let newItem = selectedItem;

      const selectedCategory = categories.find(
        (category) => category._id === selectedItem?.categoryId
      );

      if (selectedCategory?.courseId && !newItem.courseId) {
        newItem = {
          ...newItem,
          courseId: selectedCategory?.courseId,
          courseName: selectedCategory?.courseName,
        };
      }

      // Remove Course Id If orderType is not equal to dine-in
      if (currentOrder?.orderType !== '3' || !courseAutoAssignmentEnabled) {
        delete newItem.courseId;
        delete newItem.courseName;
      }
      if (isItemWithSingleSize(newItem)) {
        setHasSingleSizedItem(true);
        dispatch(
          updateCurrentSpecialsCategoryItem(parseInt(item.index), {
            ...incrementItemQuantitySingleSize(newItem),
            isSpecialItem: true,
          })
        );
      }
    }
  }, [dispatch, item.index, item.menuItems]);

  const linkTo = useRouteByMenuItemType(
    parentPage,
    currentItems.length === 1 ? currentItems[0] : {}
  );

  const handleCustomiseItemClick = (event) => {
    event.stopPropagation();
    dispatch(toggleSpecialsCategoryEditMode(item.index));
    if (currentItems.length === 1) {
      history.push(linkTo, location.state);
      dispatch(openMenuItem(currentItems[0] || {}, true));
    } else if (isHalfHalf) {
      history.push(
        `${parentPage}/specials/${specialsId}/${item.index}/half-half`,
        location.state
      );
    } else if (isFourQuarter) {
      history.push(
        `${parentPage}/specials/${specialsId}/${item.index}/four-quarter`,
        location.state
      );
    }
  };

  const getItemTitle = () => {
    if (isHalfHalf) {
      return 'Half/Half';
    } else if (isFourQuarter) {
      return 'Four Quarter';
    } else {
      return currentItems[0]?.name || item.displayName;
    }
  };

  return (
    <div className="specials-card-wrap">
      <div
        title={item.displayName}
        className={cardCls}
        onClick={hasSingleSizedItem ? () => {} : handleClick}
      >
        {currentItems[0]?.mediaPath ||
        currentItems[0]?.urlS3 ||
        currentItems[0]?.name ? (
          // later change the icon
          !hasSingleSizedItem && (
            <span
              className="customize-half-btn"
              onClick={handleCustomiseItemClick}
            >
              <span className="customize-half-btn-title">Customize</span>
              <PosIconSilverware mainColor="#000000" title="Customise" />
            </span>
          )
        ) : (
          <PosIconPlus mainColor="#fff" />
        )}

        <span className="specials-item-img-wrap">
          {currentItems[0]?.urlS3 && (
            <img src={imagePath ? imagePath : currentItems[0]?.urlS3} alt="" />
          )}
        </span>
        <span className={titleCls}>{getItemTitle()}</span>
      </div>
    </div>
  );
};

export default SpecialsCategoryItem;
