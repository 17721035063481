import React, { useState, useEffect, useMemo, useRef } from 'react';
import { PosIconApperCircle } from '../../assets/icons/PosIconApperCircle';
import clsx from 'clsx';
import { setActionsModalConfig } from '../../pages/Dashboard/action';
import { useDispatch, useSelector } from 'react-redux';
import { getItemPrice } from '../../orderEngine/utilities';
import { getInstructionString } from '../../util/getInstructionString';
import { isEmpty } from '../../util/utils';
import PriceCell from './PriceCell';

export const SingleOrderItem = ({
  showPrice,
  lightMode,
  menuItem,
  firedCourse,
  basePath,
}) => {
  const dispatch = useDispatch();
  const itemRef = useRef();
  const [deleteItemAction, setDeleteItemAction] = useState(false);
  const storageBaseUrl = useSelector(
    (state) => state.dashboardReducer?.storeConfig?.storageBaseUrl
  );
  let imagePath = '';

  if (storageBaseUrl && menuItem?.mediaPath) {
    imagePath = `${storageBaseUrl}${menuItem?.mediaPath}`;
  }

  const itemCls = clsx({
    'single-item': true,
    'delete-item': deleteItemAction,
  });

  const selectedSizes = menuItem.selectedSizes.filter((f) => f.quantity);

  const titleWithQuantity = useMemo(() => {
    const title = `${menuItem.name} - ${
      selectedSizes.length
        ? selectedSizes
            .map((m) => `${m.name}${m.cName ? ` (${m.cName})` : ''}`)
            .join(',')
        : ''
    }`;
    let variantText = '';
    selectedSizes.forEach((size) => {
      size?.variants?.forEach((variant) => {
        variantText = variantText + variant?.name;
      });
    });
    const quantity = selectedSizes.filter((size) => size.quantity > 1).length
      ? ' x' + selectedSizes.find((size) => size.quantity).quantity
      : '';

    return `${title}${variantText ? ` (${variantText})` : ''}${quantity}`;
  }, [selectedSizes, menuItem.name]);

  useEffect(() => {
    let customTimeout = 0;

    if (deleteItemAction) {
      setTimeout(() => {
        setDeleteItemAction(false);
      }, 365);
    }

    return () => {
      clearTimeout(customTimeout);
    };
  }, [deleteItemAction]);

  const instruction = getInstructionString(menuItem);

  const handleClick = () => {
    let elementData = itemRef.current.getBoundingClientRect();

    dispatch(
      setActionsModalConfig({
        show: true,
        menuItem,
        basePath,
        instruction,
        buttonData: elementData,
      })
    );
  };

  const {
    isVoided = false,
    payablePrice,
    selectedDiscount,
    courseId,
    selectedVoucher,
  } = menuItem;

  const price = getItemPrice(menuItem)?.payablePrice;
  const payPrice = !isEmpty(payablePrice) && payablePrice;
  const discountAmount = selectedDiscount && selectedDiscount.discount;
  const voucherCode = selectedVoucher && selectedVoucher.voucherCode;

  return (
    <div
      className={itemCls}
      key={menuItem._id}
      onClick={handleClick}
      ref={itemRef}
    >
      {/* single-item delete-item add-item */}
      <div className={`single-item-table${isVoided ? ' voided' : ''}`}>
        {!lightMode && (
          <div className="single-item-cell img-cell">
            <div className="single-img-wrap">
              <img
                src={
                  imagePath ? imagePath : menuItem.urlS3 ? menuItem.urlS3 : ''
                }
                alt={menuItem.name}
              />
            </div>
          </div>
        )}
        <div className="single-item-cell info-cell">
          <div className="single-body">
            <h4 className="single-title">{titleWithQuantity}</h4>
            {menuItem?.itemNotes && (
              <div className="single-actions">
                <span title={menuItem?.itemNotes || ''}>
                  {menuItem?.itemNotes ? `Notes: ${menuItem?.itemNotes}.` : ''}
                </span>
              </div>
            )}
            {!!instruction.length && (
              <div className="single-actions">
                <span title={instruction}>{instruction}</span>
              </div>
            )}
          </div>
        </div>
        <div className="single-item-price-group">
          <div className="single-item-price-group__inner">
            {showPrice && (
              <PriceCell
                firedCourse={firedCourse}
                discountAmount={discountAmount}
                voucherCode={voucherCode}
                payablePrice={payPrice}
                price={price}
                courseId={courseId}
              />
            )}
          </div>
        </div>
      </div>
      {!lightMode && (
        <button className="single-modify-btn hover-highlight" title="Edit">
          <PosIconApperCircle mainColor="#494B4C" />
        </button>
      )}
    </div>
  );
};
