import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const HalfHalfItem = ({ link, item = {}, onClick }) => {
  let animCls = clsx('card-item ', 'card-item-half');

  const storageBaseUrl = useSelector(
    (state) => state.dashboardReducer?.storeConfig?.storageBaseUrl
  );

  let imagePath = '';

  if (storageBaseUrl && item?.mediaPath) {
    imagePath = `${storageBaseUrl}${item?.mediaPath}`;
  }

  return (
    <div className={animCls} key={item._id}>
      <Link
        to={link ? link : '#'}
        className=""
        title={item.name}
        onClick={onClick}
      >
        <span className="card-inner-box">
          <span className={'card-img-wrap'}>
            <img src={imagePath ? imagePath : item.urlS3} alt={item.name} />
          </span>
          <span className="card-name">{item.name}</span>
        </span>
      </Link>
    </div>
  );
};

export default HalfHalfItem;
