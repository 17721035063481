import React, { useMemo, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import useOrder from 'hooks/useOrder';
import Favourites from 'components/Favourites';
import useCategories from 'hooks/useCategories';
import PreviousOrders from 'components/PreviousOrders';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PosIconSearch } from '../../assets/icons/PosIconSearch';
import addItemToOrder from '../../cartProcessor/addItemToOrder';
import { useActiveCategoryById } from '../../customHooks/useActiveCategoryById';
import { useMenuItemsByCategoryId } from '../../customHooks/useMenuItemsByCategoryId';
import { useMenuItemsFilterByString } from '../../customHooks/useMenuItemsFilterByString';
import {
  incrementItemQuantitySingleSize,
  isItemWithSingleSize,
  prepareItemForOrder,
} from '../../itemProcessor';
import {
  openMenuItem,
  resetCurrentHalfHalfData,
  setShowPizzaActionButton,
  updateCurrentFourQuarterData,
  updateCurrentHalfHalfData,
  updateCurrentOrder,
} from '../../pages/Dashboard/action';
import {
  orderSetupSelector,
  storeConfigSelector,
  productSetupSelector,
  specialsSelector,
  suburbsSelector,
  publicHolidaysSelector,
  activeSpecialsSelector,
} from 'pages/Dashboard/selectors';
import { ScrollableBoxUI } from '../../UI/components';
import { FiltersNav } from '../FiltersNav';
import HalfHalfItem from '../HalfHalfView/HalfHalfItem';
import { SearchMenuItems } from '../SearchMenuItems';
import SpecialsItems from '../SpecialsItems';
import { MenuItem } from './MenuItem';
import useMenuItems from 'hooks/useMenuItems';
import AlertModal from '../../components/AlertModal';

export const MenuItems = ({ ordersHistory, parentPage }) => {
  const specials = useSelector(specialsSelector);
  const activeSpecials = useSelector(activeSpecialsSelector);
  const orderSetup = useSelector(orderSetupSelector);
  const publicHolidays = useSelector(publicHolidaysSelector);
  const suburbs = useSelector(suburbsSelector);
  const productSetup = useSelector(productSetupSelector);
  const storeConfig = useSelector(storeConfigSelector);
  const categories = useCategories();
  const [{ selectedCategoryId }] = useOrder();
  const _menuItems = useMenuItems();
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const soldOutItems = useSelector((s) => s.dashboardReducer.soldOutItems);
  const courseAutoAssignmentEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.courseAutoAssignmentEnabled
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSoldOutModal, setShowSoldOutModal] = useState(false);
  const [modifierLink, setModifierLink] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchString, setSearchString] = useState('');
  const currentCategoryData = useActiveCategoryById(
    categories,
    selectedCategoryId
  );

  const menuItems = useMenuItemsByCategoryId(
    _menuItems,
    selectedCategoryId,
    soldOutItems
  );

  const currentMenuItems = useMenuItemsFilterByString({
    totalItems: _menuItems,
    menuItems,
    searchString,
  });

  const { dineInCoursesMap } = useSelector(
    (state) => state.dashboardReducer.dineInCourses
  );

  const coursesSorted = useMemo(() => {
    const courses = Object.values(dineInCoursesMap);
    return courses.sort((a, b) => a.order - b.order).filter((c) => c.isActive);
  }, [dineInCoursesMap]);

  const firstCourse = useMemo(() => {
    return coursesSorted.length > 0 ? coursesSorted[0] : null;
  }, [coursesSorted]);

  const updateSearchString = (value) => {
    setSearchString(value);
  };

  const toggleSearchBox = () => {
    setShowSearchBox(!showSearchBox);
    setSearchString('');
  };

  const isSpecialsCategory = selectedCategoryId === 'specials';
  const isOrderHistory =
    ordersHistory && selectedCategoryId === 'order_history';
  const isFavourites = false;

  const boxCls = clsx({
    'main-area-header': true,
    'hide-shadow specials': isSpecialsCategory,
  });

  const itemsWrapCls = clsx({
    'main-area-body-wrap': true,
    specials: isSpecialsCategory,
  });

  const isHalfHalfEnabled = !!productSetup?.halfHalfs?.some((h) =>
    h.categories.some((c) => c._id === selectedCategoryId)
  );

  const isFourQuarterEnabled =
    !!productSetup?.halfHalfs?.some((h) =>
      h.categories.some((c) => c._id === selectedCategoryId)
    ) && productSetup.isFourQuarterEnabled;

  const handleAddMenuItemClick = useCallback(
    (_event, item) => {
      let newItem = item;

      const selectedCategory = categories.find(
        (category) => category._id === item?.categoryId
      );

      if (
        currentOrder.menuItems.some(({ courseId }) => !!courseId) &&
        firstCourse
      ) {
        newItem = {
          courseId: firstCourse._id,
          courseName: firstCourse.name,
          ...item,
        };
      }

      if (selectedCategory?.courseId && !newItem?.courseId) {
        newItem = {
          ...newItem,
          courseId: selectedCategory?.courseId,
          courseName: selectedCategory?.courseName,
        };
      }

      // Remove Course Id If orderType is not equal to dine-in
      if (currentOrder?.orderType !== '3' || !courseAutoAssignmentEnabled) {
        delete newItem.courseId;
        delete newItem.courseName;
      }

      if (!isItemWithSingleSize(newItem))
        dispatch(openMenuItem(newItem, false));
      else {
        // Add item to order with single size
        newItem = prepareItemForOrder(
          incrementItemQuantitySingleSize(newItem),
          currentOrder
        );

        dispatch(
          updateCurrentOrder(
            addItemToOrder(
              currentOrder,
              newItem,
              activeSpecials,
              orderSetup,
              productSetup,
              suburbs,
              publicHolidays,
              storeConfig
            )
          )
        );
        history.push(`${parentPage}/order`);
      }
    },
    [currentOrder, dispatch, firstCourse, history, orderSetup]
  );

  const handleMenuItemClick = useCallback(
    (_event, item, link, isSingleSizedItem) => {
      if (!!item?.isSoldOut) {
        setModifierLink(link);
        setShowSoldOutModal(true);
        setSelectedItem(item);
      } else {
        setSelectedItem(null);
        link && !isSingleSizedItem && history.push(link);
        handleAddMenuItemClick(_event, item);
      }
    },
    [currentOrder, dispatch, firstCourse, history, orderSetup]
  );

  const handleProceed = () => {
    modifierLink && history.push(modifierLink);
    handleAddMenuItemClick({}, selectedItem);
    setShowSoldOutModal(false);
    setModifierLink('');
  };
  const handleHalfHalfItemClick = () => {
    dispatch(updateCurrentHalfHalfData(selectedCategoryId));
  };

  const handleFourQuarterItemClick = () => {
    dispatch(updateCurrentFourQuarterData(selectedCategoryId));
  };

  useEffect(() => {
    dispatch(setShowPizzaActionButton(true));
  }, []);

  useEffect(() => {
    dispatch(resetCurrentHalfHalfData());
  }, []);

  return (
    <>
      <div className="main-area" id="main-area">
        <div className="main-area-inner">
          {showSearchBox && (
            <SearchMenuItems
              searchString={searchString}
              showSearchBox={showSearchBox}
              updateSearchString={updateSearchString}
              toggleSearchBox={toggleSearchBox}
            />
          )}

          {!isOrderHistory && (
            <>
              <div className={boxCls}>
                <h1 className="category-title">{currentCategoryData.name}</h1>
                <button
                  type="button"
                  className="search-btn"
                  onClick={toggleSearchBox}
                >
                  <PosIconSearch mainColor="#484B4C" />
                </button>
                {!isSpecialsCategory && <FiltersNav />}
              </div>

              <div className={itemsWrapCls}>
                <ScrollableBoxUI>
                  <div className="main-area-body">
                    <div className="food-cards">
                      {isSpecialsCategory ? (
                        <SpecialsItems parentPage={parentPage} />
                      ) : (
                        <>
                          {isHalfHalfEnabled && (
                            <HalfHalfItem
                              item={{
                                id: selectedCategoryId,
                                name: 'Half/Half',
                                urlS3: productSetup.halfHalfPlaceholderImage,
                                mediaPath:
                                  productSetup?.halfHalfPlaceholderPath,
                              }}
                              link={`${parentPage}/category/${selectedCategoryId}/half-half`}
                              onClick={handleHalfHalfItemClick}
                            />
                          )}
                          {isFourQuarterEnabled && (
                            <HalfHalfItem
                              item={{
                                id: selectedCategoryId,
                                name: 'Four Quarter',
                                urlS3: productSetup.fourQuarterPlaceHolderImage,
                                mediaPath:
                                  productSetup?.fourQuarterPlaceHolderPath,
                              }}
                              link={`${parentPage}/category/${selectedCategoryId}/four-quarter`}
                              onClick={handleFourQuarterItemClick}
                            />
                          )}
                          {!!currentMenuItems.length &&
                            currentMenuItems.map((item, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  item={item}
                                  parentPage={parentPage}
                                  onItemClick={handleMenuItemClick}
                                />
                              );
                            })}
                        </>
                      )}
                    </div>
                  </div>
                </ScrollableBoxUI>
              </div>
            </>
          )}

          {isOrderHistory && (
            <>
              {isFavourites && <Favourites />}
              <PreviousOrders
                ordersHistory={ordersHistory}
                orderParams={{
                  currentOrder,
                  specials,
                  orderSetup,
                  productSetup,
                  suburbs,
                  publicHolidays,
                  storeConfig,
                }}
                parentPage={parentPage}
                activeCategoryId={selectedCategoryId}
              />
            </>
          )}
        </div>
      </div>

      {showSoldOutModal && (
        <AlertModal
          modalStatus={showSoldOutModal}
          toggleModal={() => setShowSoldOutModal(false)}
          header="Item Sold Out"
          description="Would you like to proceed with the Item ?"
          proceedText="Yes"
          cancelText="No"
          handleCancel={() => setShowSoldOutModal(false)}
          handleProceed={handleProceed}
          enableProceedButtonLoader={false}
        />
      )}
    </>
  );
};
